<template>
  <div class='index-wrap'>
    <div class="nav-part btwflex" :class="{'showmenue scale-1px-bottom':showMenue}" ref="navRef">
      <div class="logo-box cflex shrink">
        <div class="lg-mask"></div>
        <div class="logo">AXFSP</div>
      </div>
      <p class="slide-icon cflex iconfont" :class="{'closeicon icon-close':showMenue, 'icon-liebiao':!showMenue}" v-if="$client!='PC'" ref="slideRef" @click="clickMenue"></p>
      <ul class="tabs cflex" :class="{'mobile-tab':$client!='PC'}" v-if="$client=='PC'||showMenue" ref="menusRef">
        <li class="tab-item cflex" :class="{active:curTab==index}" :ref="item.id" v-for="(item, index) in tabs" :key="index" @click.stop="tabChange(index, item.id)">
          <span class="tbcnt" :ref="item.id + '_name'">{{item.name}}</span>
        </li>
        <div class="acblock shrink" v-if="$client=='PC'" :style="{ width: acWidth + 'px', left: acLeft + 'px' }"></div>
      </ul>
      <div class="lang-box" ref="langRef">
        <div class="lang-inner lflex">
          <div class="lang lflex">
            <img class="country" :src="curLang.img" />
<!--            <p class="lncnt">{{curLang.name}}</p>-->
          </div>
<!--          <p class="lnicon iconfont icon-arrow-down"></p>-->
        </div>
<!--        <ul class="lang-list" v-if="showLang">
          <li class="lgitem lflex" :class="{'active-lang':curLang.lang==item.lang}" v-for="(item, index) in langList" :key="index" @click="changeLang(item)">
            <img class="country" :src="item.img" />
            <p class="lncnt">{{item.name}}</p>
          </li>
        </ul>-->
      </div>
    </div>

    <div class="index-part cflex" ref="indexRef">
      <div class="wk-empty"></div>
      <div class="idx-inner inner">
        <div class="idx-top cflex" :class="{'idx-unzh-top':curLang.lang!='zh-CN'}">
          <div class="idx-info-box cflex">
            <div class="idx-info-inner">
              <template v-if="curLang.lang=='zh-CN'">
                <p class="idx-cnt-box lflex">
                  <span class="idx-cnt">{{$t('common.usecard')}}</span>
                  <span class="idx-cnt origin">{{$t('common.makeMoney')}}</span>
                </p>
                <p class="idx-cnt-box green">{{$t('common.easy')}}</p>
              </template>
              <p class="idx-cnt-box" v-if="curLang.lang!='zh-CN'">{{$t('common.usecard') + $t('common.makeMoney') + $t('common.easy')}}</p>
              <button class="contact-btn cflex" @click="toContact">{{$t('menus.contact')}}</button>
            </div>
          </div>
          <div class="idx-imgbox imgbox">
            <img class="imgs" src="../assets/index_img.png" />
          </div>
        </div>
        <ul class="idx-list cflex">
          <li class="lt-item cflex" :class="{'lt-empty':!item.title}" v-for="(item, index) in indexInfo" :key="index">
            <div class="lt-inner cflex">
              <div class="ltimg imgbox">
                <img class="imgs" :src="item.image" />
              </div>
              <div class="lt-title">{{item.title}}</div>
              <div class="lt-info">{{item.info}}</div>
            </div>
          </li>
        </ul>
      </div>
      <div class="wk-empty"></div>
    </div>

    <div class="work-part cflex" ref="workRef">
      <div class="wk-empty"></div>
      <div class="wk-inner inner">
        <h3 class="wk-title">{{$t('menus.work')}}</h3>
        <p class="wk-tinfo">{{$t('common.wkTinfo')}}</p>
        <p class="wk-secinfo">{{$t('common.wkSecinfo')}}</p>
        <div class="wk-icon-box lflex">
          <div class="wk-item cflex">
            <p class="wk-itcnt">{{$t('common.wkJoinus')}}</p>
            <div class="wkimgs imgbox">
              <img class="imgs" src="../assets/work_join.png" />
            </div>
          </div>
          <div class="wk-item cflex">
            <p class="wk-itcnt">{{$t('common.wkLogin')}}</p>
            <div class="wkimgs imgbox">
              <img class="imgs" src="../assets/work_login.png" />
            </div>
          </div>
          <div class="wk-item cflex">
            <p class="wk-itcnt">{{$t('common.wkBind')}}</p>
            <div class="wkimgs imgbox">
              <img class="imgs" src="../assets/work_bind.png" />
            </div>
          </div>
          <div class="wk-item cflex">
            <p class="wk-itcnt">{{$t('common.wkMakemoney')}}</p>
            <div class="wkimgs imgbox">
              <img class="imgs" src="../assets/work_get.png" />
            </div>
          </div>
        </div>
        <p class="wk-btcnt" v-if="$client=='PC'">{{$t('common.wkBtnInfo_1') + $t('common.wkBtnInfo_2') + $t('common.wkBtnInfo_3')}}</p>
        <div class="wk-btcnt" v-else>
          <p>{{$t('common.wkBtnInfo_1')}}</p>
          <p>{{$t('common.wkBtnInfo_2')}}</p>
          <p>{{$t('common.wkBtnInfo_3')}}</p>
        </div>
      </div>
      <div class="wk-empty"></div>
    </div>

    <div class="calculator-part cflex" ref="calculatorRef">
      <img class="addimg" src="../assets/cal_add.png" />
      <img class="methodimg" src="../assets/cal_method.png" />
      <div class="wk-empty"></div>
      <div class="cal-inner inner">
        <h3 class="cal-title">{{$t('menus.calculator')}}</h3>
        <p class="cal-tinfo">{{$t('common.calTinfo_1')}}</p>
        <p class="cal-tinfo">{{$t('common.calTinfo_2')}}</p>
        <div class="cal-ipt-box cflex">
          <div class="ipt-list">
            <div class="ipt-item lflex" :class="{'ipt-unzh-item':curLang.lang!='zh-CN', 'ipt-unzh-item scale-1px-bottom':$client!='PC'&&curLang.lang!='zh-CN'}">
              <p class="ipt-name">{{$t('common.calDayGet')}}</p>
              <input class="ipt" :class="{erript:isErr=='dayGet'}" v-model="dayGet" type="number" oninput="if(value.length>11)value=value.slice(0,11)" />
              <p class="ipt-unit">{{$t('common.calUnit_mn')}}</p>
              <p class="ipt-err" v-if="isErr=='dayGet'">{{$t('common.calInputTip')}}</p>
            </div>
            <div class="ipt-item lflex" :class="{'ipt-unzh-item':curLang.lang!='zh-CN', 'ipt-unzh-item scale-1px-bottom':$client!='PC'&&curLang.lang!='zh-CN'}">
              <p class="ipt-name">{{$t('common.calDayPay')}}</p>
              <input class="ipt" :class="{erript:isErr=='dayPay'}" v-model="dayPay" type="number" oninput="if(value.length>11)value=value.slice(0,11)" />
              <p class="ipt-unit">{{$t('common.calUnit_mn')}}</p>
              <p class="ipt-err" v-if="isErr=='dayPay'">{{$t('common.calInputTip')}}</p>
            </div>
            <div class="ipt-item lflex" :class="{'ipt-unzh-item':curLang.lang!='zh-CN', 'ipt-unzh-item scale-1px-bottom':$client!='PC'&&curLang.lang!='zh-CN'}">
              <p class="ipt-name">{{$t('common.calDayPayNum')}}</p>
              <input class="ipt" :class="{erript:isErr=='dayPayNum'}" v-model="dayPayNum" type="number" oninput="if(value.length>11)value=value.slice(0,11)" />
              <p class="ipt-unit">{{$t('common.calUnit_count')}}</p>
              <p class="ipt-err" v-if="isErr=='dayPayNum'">{{$t('common.calInputTip')}}</p>
            </div>
            <div class="ipt-item lflex" :class="{'ipt-unzh-item':curLang.lang!='zh-CN', 'ipt-unzh-item scale-1px-bottom':$client!='PC'&&curLang.lang!='zh-CN'}">
              <p class="ipt-name">{{$t('common.calDayGetNum')}}</p>
              <input class="ipt" :class="{erript:isErr=='dayGetNum'}" v-model="dayGetNum" type="number" oninput="if(value.length>11)value=value.slice(0,11)" />
              <p class="ipt-unit">{{$t('common.calUnit_count')}}</p>
              <p class="ipt-err" v-if="isErr=='dayGetNum'">{{$t('common.calInputTip')}}</p>
            </div>
            <div class="ipt-item lflex" :class="{'ipt-unzh-item':curLang.lang!='zh-CN', 'ipt-unzh-item scale-1px-bottom':$client!='PC'&&curLang.lang!='zh-CN'}">
              <p class="ipt-name">{{$t('common.calDayNum')}}</p>
              <input class="ipt" :class="{erript:isErr=='dayNum'}" v-model="dayNum" type="number" oninput="if(value.length>11)value=value.slice(0,11)" />
              <p class="ipt-unit">{{$t('common.calDay')}}</p>
              <p class="ipt-err" v-if="isErr=='dayNum'">{{$t('common.calInputTip')}}</p>
            </div>
            <button class="tocalbtn cflex" @click="toCalFun">
              <span class="calbtncnt">{{$t('common.calTocal')}}</span>
            </button>
          </div>
          <div class="ipt-answer">
            <h4 class="ipt-as-title">{{$t('common.calAnswerTip')}}</h4>
            <h4 class="ipt-as-val" v-if="total">
              <span class="valcnt">{{total}}</span>
              <span class="valunit">{{$t('common.calUnit_mn')}}</span>
            </h4>
            <h4 class="ipt-as-val" v-else>--</h4>
          </div>
        </div>
      </div>
      <div class="wk-empty small-empty"></div>
    </div>

    <div class="contact-part" ref="contactRef">
      <div class="tat-top cflex" :class="{'tat-unzh-top':curLang.lang!='zh-CN'}">
        <div class="wk-empty"></div>
        <div class="tat-inner tat-top-inner inner">
          <h3 class="tat-title">{{$t('common.tatTitle')}}</h3>
          <div class="tat-cnt">
            <div class="round shrink"></div>
            <span class="tat-cnt-bk">{{$t('common.tatCnt_1')}}</span>
            <span class="tat-cnt-green">{{$t('common.tatCnt_1_green')}}</span>
          </div>
          <div class="tat-cnt">
            <div class="round shrink"></div>
            <span class="tat-cnt-bk">{{$t('common.tatCnt_2')}}</span>
            <span class="tat-cnt-green">{{$t('common.tatCnt_2_green')}}</span>
            <span class="tat-cnt-bk">{{$t('common.tatCnt_2_1')}}</span>
          </div>
          <div class="tat-cnt">
            <div class="round shrink"></div>
            <span class="tat-cnt-bk">{{$t('common.tatCnt_3')}}</span>
            <span class="tat-cnt-green">{{$t('common.tatCnt_3_green')}}</span>
            <span class="tat-cnt-bk">{{$t('common.tatCnt_3_1')}}</span>
          </div>
          <div class="tat-cnt">
            <div class="round shrink"></div>
            <span class="tat-cnt-bk">{{$t('common.tatCnt_4')}}</span>
            <span class="tat-cnt-green">{{$t('common.tatCnt_4_green')}}</span>
            <span class="tat-cnt-bk">{{$t('common.tatCnt_4_1')}}</span>
          </div>
          <img class="tat-zan" src="../assets/cal_zan.png" />
          <img class="tat-map" src="../assets/cal_map.png" />
        </div>
        <div class="wk-empty"></div>
      </div>
      <div class="tat-bottom cflex">
        <div class="wk-empty"></div>
        <div class="tat-inner tat-bottom-inner inner">
          <h3 class="tat-title tat-bt-title">{{$t('menus.contact')}}</h3>
          <p class="tat-info">{{$t('common.tatTinfo')}}</p>
<!--          <div class="tat-list cflex">
            <div class="tat-item cflex">
              <div class="tatimgs imgbox">
                <img class="imgs" src="../assets/ct_phone.png" />
              </div>
              <p class="tat-item-cnt">{{$t('common.tatTel')}}：123456</p>
            </div>
            <div class="tat-item cflex">
              <div class="tatimgs imgbox">
                <img class="imgs" src="../assets/ct_message.png" />
              </div>
              <p class="tat-item-cnt">{{$t('common.tatApp')}}：<a :href="whatsapp+'send?phone='+whatsNum+'&text=Hi'" target="_blank">{{ whatsNum }}</a></p>
            </div>
            <div class="tat-item cflex">
              <div class="tatimgs imgbox">
                <img class="imgs" src="../assets/ct_xing.png" />
              </div>
              <p class="tat-item-cnt">{{$t('common.tatE_mail')}}：biz@axfsp.com</p>
            </div>
          </div>-->
          <p style="font-size: 26px;color: #fff;line-height: 35px;text-align: center;margin:0 auto; margin-top: 40px;margin-bottom: -40px">MILORG ENTERTAINMENT INC</p>
        </div>
        <div class="wk-empty"></div>
      </div>
    </div>

  </div>
</template>

<script>
import { updateI18n } from '../i18n/getPageLang'
import index_set from '../assets/index_set.png'
import index_jishu from '../assets/index_jishu.png'
import index_get from '../assets/index_get.png'

import cntr_Chinese from '../assets/cntr_Chinese.png'
import cntr_English from '../assets/cntr_English.png'
import cntr_Spanish from '../assets/cntr_Spanish.png'
import cntr_Indonesian from '../assets/cntr_Indonesian.png'
import cntr_Portuguese from '../assets/cntr_Portuguese.png'
import cntr_Vietnamese from '../assets/cntr_Vietnamese.png'
import cntr_Thai from '../assets/cntr_Thai.png'
import cntr_Turkish from '../assets/cntr_Turkish.png'

export default {
  components: {},
  data() {
    return {
      curTab: 0,
      tabs: [{id: 'index', name: 'Ficha técnica'}, {id: 'work', name: 'Como trabalhar'}, {id: 'calculator', name: 'Calculadora de comissões'}, {id: 'contact', name: 'Fale conosco fale conosco'}],
      acWidth: 20,
      acLeft: 0,   //滑块距离右边的距离
      showLang: false,
      showMenue: false,   //移动端是否展示菜单
      curLang: { id: 'Portuguese', lang: 'pt', img: cntr_Portuguese, name: 'Português' },
      langList: [
        { id: 'Portuguese', lang: 'pt', img: cntr_Portuguese, name: 'Português' }/*,
        { id: 'Chinese', lang: 'zh-CN', img: cntr_Chinese, name: '简体中文' },
        { id: 'English', lang: 'en', img: cntr_English, name: '英语' },
        { id: 'Spanish', lang: 'es', img: cntr_Spanish, name: '西班牙语' },
        { id: 'Indonesian', lang: 'id', img: cntr_Indonesian, name: '印尼语' },
        { id: 'Vietnamese', lang: 'vi', img: cntr_Vietnamese, name: '越南语' },
        { id: 'Thai', lang: 'th', img: cntr_Thai, name: '泰语' },
        { id: 'Turkish', lang: 'tr', img: cntr_Turkish, name: '土耳其语' }*/
      ],
      indexInfo: [
        {},
        { id: 'idxset', title: 'Fornecer configurações para você', infoId: 'idxsetInfo', info: 'A configuração é fornecida. Você só precisa enviar as informações necessárias para nós.', image: index_set },
        { id: 'idxManage', title: 'Ensine técnicas de operação', infoId: 'idxManageInfo', info: 'Estamos sempre disponíveis para responder às suas dúvidas', image: index_jishu },
        { id: 'idxProfit', title: 'Baixo limiar alto rendimento', infoId: 'idxProfitInfo', info: 'Fácil de operar, você pode ganhar comissões a qualquer momento, em qualquer lugar com um telefone celular', image: index_get },
        {}
      ],
      dayGet: '',
      dayPay: '',
      dayPayNum: '',
      dayGetNum: '',
      dayNum: '',
      rate: 2,
      single: 3,
      total: '',
      isErr: '',
      indexHt: '',
      workHt: '',
      calculatorHt: '',
      contactHt: '',
      whatsNum:'',
      whatsArray: ['+60199507342','+6000000001','+6000000002','+6000000003','+6000000004','+6000000005','+6000000006','+6000000007'],
      whatsapp: 'https://api.whatsapp.com/'
    }
  },
  created() {
    this.isMobile();
    this.setLangList();
    this.changeLang();
  },
  mounted() {
    this.setRight();
    document.addEventListener("scroll",this.handleScroll, false);
    document.addEventListener('click', this.hidePanel, false);
  },
  destroyed() {
    document.removeEventListener('click', this.hidePanel)
    if (this.$client == 'PC') document.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    setLangList() {    //初始化语言列表
      //let _lang = 'pt';
      this.tabs = this.foreachArr(this.tabs, 'name', 'menus', 'id');

     /* this.langList = this.foreachArr(this.langList, 'name', 'lang', 'id');
      let _idx = this.langList.findIndex(item => item.lang == _lang);*/
      /*this.curLang = this.langList[_idx!=-1 ? _idx : 0];*/

      this.indexInfo = this.foreachArr(this.indexInfo, 'title', 'common', 'id', 'info', 'common', 'infoId');
    },
    foreachArr(arr, key, parName, flag, key2, par2, flag2) {   //key:列表属性名  parName:父级属性名  flag:设置的语言名
      arr.forEach(item => {
        let _tn = item[key] ? `${parName}.${item[flag]}` : '', _tn_2 = key2&&item[key2] ? `${par2}.${item[flag2]}` : '';
        if (_tn) item[key] = this.$i18n.t(_tn);
        if (_tn_2) item[key2] = this.$i18n.t(_tn_2);
      })
      return arr;
    },
    changeLang(item) {   //切换语言
      this.curLang = { id: 'Portuguese', lang: 'pt', img: cntr_Portuguese, name: 'Português' };
      this.showLang = false;
      window.localStorage.setItem("lang", 'pt');
      updateI18n(this);  //更新语言系统
      this.setLangList();
    },
    tabChange(index, id) {    //tab切换
      this.curTab = index;
      this.$client != 'PC' ? this.showMenue = false : this.setRight('tab');
      this.$refs[`${id}Ref`].scrollIntoView(true);
    },
    clickMenue() {    //点击了菜单控制按钮
      this.showMenue = !this.showMenue;
    },
    setRight(type) {
      this.$nextTick(() => {
        if (this.$client == 'PC'){
          let _id = this.tabs[this.curTab].id, _parWidth = this.getWdOrHg(_id, 'width'), _childWidth =  this.getWdOrHg(`${_id}_name`, 'width');
          this.acWidth = _childWidth;
          this.acLeft = this.curTab*_parWidth + _parWidth/2 - _childWidth/2;
        }

        if (!type){   //第一次进入，获取各个part的高度
          setTimeout(() => {
            this.indexHt = this.getWdOrHg('indexRef');
            this.workHt = this.getWdOrHg('workRef');
            this.calculatorHt = this.getWdOrHg('calculatorRef');
            this.contactHt = this.getWdOrHg('contactRef');
            console.log('--indexHt--', this.indexHt, '---workHt--', this.workHt, '--calculatorHt---', this.calculatorHt, '---contactHt---', this.contactHt);
          }, 500)
        }
      })
    },
    getWdOrHg(name, type) {
      let { offsetWidth, offsetHeight } = type ? this.$refs[`${name}`][0] : this.$refs[`${name}`];
      return type == 'width' ? offsetWidth : offsetHeight;
    },
    handleScroll() {    //监听页面滚动
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      let { indexHt, workHt, calculatorHt, contactHt } = this;
      if (scrollTop <= indexHt){
        this.curTab = 0;
      }else if (scrollTop > indexHt && scrollTop <= (workHt+indexHt)){
        this.curTab = 1;
      }else if (scrollTop > (workHt+indexHt) && scrollTop <= (workHt+indexHt+calculatorHt)){
        this.curTab = 2;
      }else {
        this.curTab = 3;
      }
      this.setRight('scroll');
    },
    openlangList() {
      this.showLang = !this.showLang;
    },
    hidePanel(e) {   //点击空白处关闭弹窗
      let _op = this.$refs.langRef;
      if (_op && !_op.contains(e.target)) this.showLang = false;

      if (this.$client != 'PC' && this.showMenue){
        let _nav = this.$refs.navRef, _menue = this.$refs.menusRef;
        if (_nav && !_nav.contains(e.target) && (_menue && !_menue.contains(e.target))) this.showMenue = false;
      }
    },
    toContact() {   //联系我们
      // this.$refs[`contactRef`].scrollIntoView(true);
      window.open('https://api.whatsapp.com/send?phone=553398358057');
    },
    toCalFun() {    //马上计算佣金
      let { dayGet, dayPay, dayPayNum, dayGetNum, dayNum, rate, single } = this;
      this.total = (dayGet*rate + dayPay*rate + dayPayNum*single + dayGetNum*single)*(dayNum||1);
    },
    isMobile() {
      this.whatsNum=this.whatsArray[Math.floor(Math.random() * this.whatsArray.length + 1)-1];
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      if(flag) {
        this.whatsapp = 'whatsapp://'
      }
    }
  }
}
</script>

<style lang="less" scoped>
// PC端比例是 100 / 64 = 1.5625;
.index-wrap{
  min-height: 100%;
  background: #fff;
  .shrink{
    flex-shrink: 0;
  }
  .nav-part{
    height: 100px;
    align-items: flex-end;
    background: #1CAD4E;
    position: sticky;
    top: 0;
    z-index: 100;
    .logo-box{
      width: 25%;
      height: 95px;
      margin-right: 64px;
      position: relative;
      overflow: hidden;
    }
    .lg-mask{
      width: 200%;
      height: 100%;
      background: #fff;
      position: absolute;
      top: 0;
      right: 40px;
      z-index: 1;
      transform: skewX(40deg);
    }
    .logo{
      font-size: 40px;
      color: #333;
      font-weight: 700;
      line-height: 40px;
      position: relative;
      z-index: 5;
    }
    .tabs{
      flex: 1;
      height: 100%;
      position: relative;
      box-sizing: border-box;
    }
    .tab-item{
      flex: 1;
      height: 100%;
      font-size: 14px;
      color: #fff;
      font-weight: 500;
      text-align: center;
      padding: 0 10px;
      cursor: pointer;
      box-sizing: border-box;
      &.active{
        font-size: 18px;
      }
    }
    .acblock{
      width: 36px;
      height: 2px;
      background: #fff;
      position: absolute;
      bottom: 28px;
      left: 0;
      border-radius: 5px;
      transition: all 0.2s ease;
    }
    .lang-box{
      height: 100%;
      margin: 0 44px;
      cursor: pointer;
      position: relative;
      .lang-inner{
        height: 100%;
      }
      .lang{
        width: 104px;
        flex-direction: column;
      }
      .country{
        width: 56px;
        margin-bottom: 5px;
      }
      .lncnt{
        font-size: 16px;
        color: #fff;
        text-align: center;
        line-height: 16px;
      }
      .lnicon{
        font-size: 18px;
        color: #fff;
        line-height: 18px;
        position: relative;
        top: -15px;
      }
      .lang-list{
        min-width: 190px;
        background: #fff;
        padding: 10px;
        position: absolute;
        top: 90%;
        right: 0;
        z-index: 1000;
        border-radius: 4px;
        box-sizing: border-box;
        border: 1px solid rgba(0,0,0,.15);
      }
      .lgitem{
        height: 60px;
        &:hover, &.active-lang{
          .lncnt{
            color: #1CAD4E;
          }
        }
        .country{
          margin-bottom: 0;
        }
        .lncnt{
          color: #3B3D45;
          font-weight: 500;
          margin-left: 10px;
        }
      }
    }
  }
  .index-part{
    background: #fff;
    padding: 72px 0 121px;
    align-items: flex-start;
    .idx-top{
      align-items: flex-start;
      margin-bottom: 114px;
      &.idx-unzh-top{
        margin-top: 0;
        .idx-info-box{
          justify-content: flex-start;
        }
        .idx-cnt-box{
          padding-right: 20px;
          position: absolute;
          top: 150px;
          word-break: break-all;
        }
        .contact-btn{
          margin-top: 240px;
        }
        .idx-imgbox{
          margin-top: 130px;
        }
      }
    }
    .idx-info-box{
      flex: 1.5;
      margin-top: 37px;
    }
    .idx-imgbox{
      flex: 1;
      justify-content: flex-start;
      .imgs{
        max-width: 431px;
      }
    }
    .idx-cnt-box{
      font-size: 80px;
      color: #3B3D45;
      font-weight: bold;
      line-height: 100px;
    }
    .origin{
      color: #FF7F00;
    }
    .green{
      color: #1CAD67;
    }
    .contact-btn{
      width: 320px;
      min-height: 80px;
      font-size: 38px;
      color: #fff;
      line-height: 38px;
      background: #1CAD67;
      padding: 10px 0;
      margin-top: 40px;
      border: none;
      border-radius: 3px;
      cursor: pointer;
      box-sizing: border-box;
      box-shadow: 0px 8px 10px rgba(0, 215, 126, 0.35);
      &:hover{
        background: #20c374;
      }
    }
    .idx-list{
      align-items: flex-start;
    }
  }
  .lt-item{
    flex: 1;
    &.lt-empty{
      flex: 0.4;
    }
    .lt-inner{
      flex-direction: column;
    }
    .ltimg{
      width: 106px;
      height: 106px;
      margin-bottom: 34px;
      .imgs{
        width: auto;
        height: 100%;
      }
    }
    .lt-title{
      font-size: 28px;
      color: #3B3D45;
      font-weight: 500;
      text-align: center;
      line-height: 40px;
      margin-bottom: 16px;
    }
    .lt-info{
      max-width: 192px;
      font-size: 16px;
      color: #737374;
      text-align: center;
      line-height: 24px;
    }
  }
  .wk-empty{
    flex: 0.2;
    height: 200px;
    &.small-empty{
      flex: 0.1;
    }
  }
  .inner{
    flex: 1;
  }
  .work-part{
    background: #1CAD67;
    padding: 100px 0 183px;
    .wk-title{
      font-size: 70px;
      color: #fff;
      font-weight: 500;
      line-height: 98px;
      margin-bottom: 26px;
    }
    .wk-tinfo{
      font-size: 36px;
      color: #fff;
      line-height: 50px;
      margin-bottom: 20px;
    }
    .wk-secinfo{
      font-size: 32px;
      color: #fff;
      line-height: 45px;
      margin-bottom: 158px;
    }
    .wk-icon-box{
      align-items: flex-end;
      margin-bottom: 50px;
    }
    .wk-item{
      flex: 1;
      justify-content: space-between;
      flex-direction: column;
      margin: 0 30px;
      &:last-child{
        .wkimgs{
          &::after, &::before{
            display: none;
          }
        }
      }
    }
    .wk-itcnt{
      font-size: 24px;
      color: #fff;
      text-align: center;
      line-height: 33px;
      margin-bottom: 30px;
    }
    .wkimgs{
      max-width: 266px;
      max-height: 266px;
      position: relative;
      overflow: visible;
      &::before{
        content: '';
        width: 100%;
        height: 100%;
        background: #1CAD67;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
      }
      &::after{
        content: '';
        width: 100%;
        height: 4px;
        background: #CECECE;
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 1;
        transform: translateY(-50%);
      }
      .imgs{
        position: relative;
        z-index: 5;
      }
    }
    .wk-btcnt{
      font-size: 28px;
      color: #fff;
      line-height: 40px;
    }
  }
  .calculator-part{
    background: #fff;
    padding: 100px 0 80px;
    position: relative;
    .addimg{
      width: 260px;
      position: absolute;
      top: 54px;
      right: 0;
      z-index: 1;
    }
    .methodimg{
      width: 206px;
      position: absolute;
      bottom: 10px;
      left: 0;
      z-index: 1;
    }
    .cal-inner{
      position: relative;
      z-index: 5;
    }
    .cal-title{
      font-size: 70px;
      color: #3B3D45;
      font-weight: 500;
      line-height: 98px;
      margin-bottom: 16px;
    }
    .cal-tinfo{
      font-size: 28px;
      color: #5B5B5D;
      line-height: 41px;
    }
    .cal-ipt-box{
      margin-top: 74px;
    }
    .ipt-list{
      flex: 1.2;
      padding-right: 100px;
      .ipt-item{
        height: 62px;
        position: relative;
        &.ipt-unzh-item{
          padding-top: 30px;
          .ipt-name{
            width: 100%;
            font-weight: 500;
            color: #000;
            position: absolute;
            top: 5px;
          }
        }
      }
      .ipt-name{
        width: 168px;
        font-size: 22px;
        color: #666;
        line-height: 30px;
      }
      .ipt{
        flex: 1;
        font-size: 30px;
        color: #1CAD67;
        font-weight: 500;
        padding: 10px 35px;
        border: none;
        outline: none;
        border-bottom: 1px solid #707070;
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
        }
        &.erript{
          border-color: #f00;
        }
      }
      .ipt-unit{
        font-size: 20px;
        color: #666;
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
      }
      .ipt-err{
        font-size: 14px;
        color: #f00;
        position: absolute;
        bottom: 20px;
        right: 90px;
        z-index: 10;
      }
      .tocalbtn{
        width: 570px;
        height: 77px;
        font-size: 36px;
        color: #fff;
        line-height: 36px;
        background: #1CAD67 ;
        margin-top: 66px;
        border: none;
        outline: none;
        cursor: pointer;
        &:hover{
          background: #20c374;
        }
      }
    }
    .ipt-answer{
      flex: 1;
      text-align: center;
      .ipt-as-title{
        font-size: 46px;
        color: #000;
        font-weight: 500;
        line-height: 65px;
      }
      .ipt-as-val{
        font-size: 110px;
        color: #FF7F00;
        font-weight: 500;
        line-height: 154px;
      }
      .valunit{
        font-size: 46px;
        line-height: 46px;
      }
    }
  }
  .contact-part{
    background: #fff;
    padding-top: 103px;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    &::before{
      content: '';
      width: 245px;
      height: 130px;
      background: #1CAD67;
      position: absolute;
      top: 0;
      left: -240px;
      z-index: 1;
      transform: skewX(-60deg);
    }
    &::after{
      content: '';
      width: 245px;
      height: 130px;
      background: #1CAD67;
      position: absolute;
      top: 0;
      right: -240px;
      z-index: 1;
      transform: skewX(60deg);
    }
    .tat-top, .tat-bottom{
      align-items: flex-start;
    }
    .tat-top{
      margin-bottom: 327px;
    }
    .tat-bottom{
      background: #4B4B4B;
      padding: 47px 0  67px;
      position: relative;
      z-index: 5;
    }
    .tat-inner{
      position: relative;
    }
    .tat-title{
      font-size: 70px;
      color: #3B3D45;
      font-weight: 500;
      line-height: 98px;
      margin-bottom: 24px;
      position: relative;
      z-index: 5;
      &.tat-bt-title{
        color: #fff;
        margin-bottom: 19px;
      }
    }
    .tat-cnt{
      font-size: 30px;
      color: #3B3D45;
      line-height: 42px;
      margin-bottom: 26px;
      padding-left: 52px;
      position: relative;
      z-index: 5;
      .tat-cnt-green{
        font-size: 36px;
        color: #1CAD67 ;
      }
    }
    .round{
      width: 30px;
      height: 30px;
      background: #1CAD67;
      margin-right: 22px;
      border-radius: 50%;
      position: absolute;
      top: 7px;
      left: 0;
    }
    .tat-zan{
      width: 235px;
      position: absolute;
      top: -81px;
      right: 0;
      z-index: 1;
    }
    .tat-map{
      width: 100%;
      position: absolute;
      top: 406px;
      left: 50%;
      z-index: 1;
      transform: translateX(-50%);
    }
    .tat-info{
      font-size: 26px;
      color: #fff;
      line-height: 37px;
      margin-bottom: 42px;
    }
    .tat-item{
      flex: 1;
      flex-direction: column;
    }
    .tatimgs{
      width: 79px;
      height: 79px;
    }
    .tat-item-cnt{
      font-size: 24px;
      color: #fff;
      margin-top: 13px;
    }
    .tat-item-cnt a{
      color: #fff;
      text-decoration: none;
    }
  }
}






@media screen and (max-width: 750px) {
  .index-wrap{
    .nav-part{
      height: 0.9rem;
      align-items: flex-end;
      justify-content: center;
      background: #1CAD4E;
      position: sticky;
      top: 0;
      z-index: 100;
      &.showmenue{
        background: #333;
        &::after{
          background: #707070;
        }
      }
      .slide-icon{
        width: 0.9rem;
        height: 100%;
        font-size: 0.3rem;
        color: #fff;
        font-weight: bold;
        position: absolute;
        top: 0;
        left: 0;
        &.closeicon{
          font-size: 0.24rem;
        }
      }
      .tabs{
        display: block;
        flex: none;
        width: 100%;
        height: auto;
        background: #333;
        padding: 0.14rem 0 0.3rem;
        position: absolute;
        top: 0.9rem;
        left: 0;
        z-index: 100;
        box-sizing: border-box;
      }
      .tab-item{
        flex: none;
        height: 0.72rem;
        font-size: 0.3rem;
        color: #fff;
        font-weight: normal;
        padding-left: 0.6rem;
        justify-content: flex-start;
        &.active{
          font-size: 0.34rem;
          color: #1CAD4E;
          font-weight: 500;
        }
      }
      .logo-box{
        width: auto;
        height: 100%;
        margin-right: 0;
        position: relative;
        overflow: hidden;
      }
      .lg-mask{
        display: none;
      }
      .logo{
        font-size: 0.36rem;
        color: #fff;
        font-weight: 700;
        line-height: 0.42rem;
        position: relative;
        z-index: 5;
      }
      .lang-box{
        height: 100%;
        margin: 0;
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 0;
        .lang-inner{
          height: 100%;
          padding: 0 0.3rem;
        }
        .lang{
          width: auto;
          flex-direction: column;
        }
        .country{
          width: 0.36rem;
          margin-bottom: 0;
          margin-right: 0.08rem;
        }
        .lncnt{
          display: none;
        }
        .lnicon{
          font-size: 0.2rem;
          line-height: 0.2rem;
          top: auto;
          transform: scale(0.8);
        }
        .lang-list{
          min-width: 3rem;
          padding: 0.2rem;
          border-radius: 0.08rem;
          border: 0.01rem solid rgba(0, 0, 0, 0.15);
        }
        .lgitem{
          height: 0.6rem;
          .country{
            margin-bottom: 0;
          }
          .lncnt{
            display: block;
            font-size: 0.26rem;
            text-align: left;
            line-height: 0.26rem;
            word-break: break-all;
            margin-left: 0.2rem;
          }
        }
      }
    }
    .index-part{
      background: #fff;
      padding: 0.43rem 0.3rem 0.78rem 0.52rem;
      align-items: flex-start;
      .idx-top{
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 0.72rem;
        &.idx-unzh-top{
          margin-top: 0;
          position: relative;
          .idx-info-box{
            justify-content: flex-start;
          }
          .idx-cnt-box{
            padding-right: 20px;
            position: absolute;
            top: 0;
            word-break: break-all;
          }
          .contact-btn{
            margin-top: 1.78rem;
          }
          .idx-imgbox{
            margin-top: 0.7rem;
          }
        }
      }
      .idx-info-box{
        flex: none;
        margin-top: 0;
        justify-content: flex-start;
      }
      .idx-imgbox{
        flex: none;
        justify-content: flex-start;
        .imgs{
          max-width: auto;
          width: 2.54rem;
        }
      }
      .idx-cnt-box{
        font-size: 0.5rem;
        color: #3B3D45;
        font-weight: bold;
        line-height: 0.66rem;
      }
      .origin{
        color: #FF7F00;
      }
      .green{
        color: #1CAD67;
      }
      .contact-btn{
        width: 1.8rem;
        min-height: 0.6rem;
        font-size: 0.28rem;
        color: #fff;
        line-height: 0.28rem;
        background: #1CAD67;
        margin-top: 0.46rem;
        border: none;
        border-radius: 0.06rem;
        cursor: pointer;
        box-shadow: 0 0.16rem 0.2rem rgba(0, 215, 126, 0.35);
        &:hover{
          background: #20c374;
        }
      }
    }
    .idx-list{
      flex-direction: column;
    }
    .lt-item{
      flex: none;
      width: 100%;
      margin-bottom: 0.36rem;
      &:nth-of-type(4){
        margin-bottom: 0;
      }
      &.lt-empty{
        display: none;
      }
      .lt-inner{
        flex-direction: column;
      }
      .ltimg{
        width: 0.66rem;
        height: 0.66rem;
        margin-bottom: 0.22rem;
        .imgs{
          width: auto;
          height: 100%;
        }
      }
      .lt-title{
        font-size: 0.3rem;
        color: #3B3D45;
        font-weight: 500;
        line-height: 0.42rem;
        margin-bottom: 0.08rem;
      }
      .lt-info{
        max-width: 100%;
        font-size: 0.26rem;
        color: #737374;
        text-align: center;
        line-height: 0.37rem;
      }
    }
    .wk-empty{
      display: none;
      &.small-empty{
        flex: 0.1;
      }
    }
    .inner{
      flex: 1;
    }
    .work-part{
      background: #1CAD67;
      padding: 0.54rem 0.3rem 0.6rem;
      .wk-title{
        font-size: 0.5rem;
        color: #fff;
        font-weight: 500;
        text-align: center;
        line-height: 0.7rem;
        margin-bottom: 0.25rem;
      }
      .wk-tinfo{
        font-size: 0.3rem;
        color: #fff;
        font-weight: 500;
        text-align: center;
        line-height: 0.42rem;
        margin-bottom: 0.28rem;
      }
      .wk-secinfo{
        width: 5.72rem;
        font-size: 0.26rem;
        color: #fff;
        text-align: center;
        line-height: 0.4rem;
        margin: 0 auto 0.28rem;
      }
      .wk-icon-box{
        display: block;
        align-items: flex-end;
        margin-bottom: 0.34rem;
      }
      .wk-item{
        flex: none;
        display: block;
        margin: 0 0 0.5rem 0;
        position: relative;
        &:last-child{
          margin: 0;
          .wkimgs{
            &::after, &::before{
              display: none;
            }
          }
        }
      }
      .wk-itcnt{
        font-size: 0.28rem;
        color: #fff;
        font-weight: 500;
        text-align: left;
        line-height: 0.4rem;
        margin-bottom: 0;
        position: absolute;
        top: 50%;
        left: 1.78rem;
        transform: translateY(-50%);
      }
      .wkimgs{
        max-width: 1.58rem;
        max-height: 1.58rem;
        position: relative;
        overflow: visible;
        &::before{
          content: '';
          width: 100%;
          height: 100%;
          background: #1CAD67;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 2;
        }
        &::after{
          content: '';
          width: 0.02rem;
          height: 0.5rem;
          background: #CECECE;
          position: absolute;
          top: auto;
          bottom: -0.5rem;
          left: 50%;
          z-index: 10;
          transform: translateX(-50%);
        }
        .imgs{
          position: relative;
          z-index: 5;
        }
      }
      .wk-btcnt{
        width: 6.6rem;
        font-size: 0.3rem;
        color: #fff;
        text-align: center;
        line-height: 0.46rem;
        margin: 0 auto;
      }
    }
    .calculator-part{
      background: #fff;
      padding: 0.55rem 0.3rem 0.66rem;
      position: relative;
      .addimg{
        width: 0.98rem;
        position: absolute;
        top: 0.44rem;
        right: 0;
        z-index: 1;
      }
      .methodimg{
        width: 1.98rem;
        position: absolute;
        bottom: 1.54rem;
        left: 0;
        z-index: 1;
      }
      .cal-inner{
        position: relative;
        z-index: 5;
      }
      .cal-title{
        font-size: 0.5rem;
        color: #3B3D45;
        font-weight: 500;
        text-align: center;
        line-height: 0.7rem;
        margin-bottom: 0.33rem;
      }
      .cal-tinfo{
        font-size: 0.26rem;
        color: #5B5B5D;
        text-align: center;
        line-height: 0.38rem;
      }
      .cal-ipt-box{
        display: block;
        margin-top: 0.48rem;
      }
      .ipt-list{
        flex: none;
        width: 100%;
        padding-right: 0;
        box-sizing: border-box;
        .ipt-item{
          height: auto;
          margin-bottom: 0.35rem;
          position: relative;
          &:nth-of-type(5){
            margin-bottom: 0.6rem;
          }
          &.ipt-unzh-item{
            padding-top: 0.5rem;
            margin-bottom: 0.2rem;
            &:nth-of-type(5){
              margin-bottom: 0.85rem;
            }
            &::after{
              background: #BABABA;
            }
            .ipt-name{
              width: 100%;
              font-weight: 500;
              color: #000;
              position: absolute;
              top: 5px;
            }
            .ipt{
              border: none;
              border-radius: none;
              outline: none;
              &:active, &:focus{
                background: none;
                border-radius: none;
              }
            }
            .ipt-unit{
              top: auto;
              bottom: 0.15rem;
              transform: none;
            }
          }
        }
        .ipt-name{
          width: 2.22rem;
          font-size: 0.28rem;
          color: #666;
          font-weight: 500;
          line-height: 0.4rem;
        }
        .ipt{
          flex: 1;
          font-size: 0.3rem;
          color: #1CAD67;
          font-weight: 500;
          padding: 0.2rem;
          border: 0.01rem solid #BABABA;
          outline: none;
          border-radius: none;
          -webkit-appearance: none;
          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
          }
          &:active, &:focus{
            background: none;
            border-radius: none;
          }
          &.erript{
            border-color: #f00;
          }
        }
        .ipt-unit{
          font-size: 0.28rem;
          color: #666;
          font-weight: 500;
          position: absolute;
          top: 50%;
          right: 0.26rem;
          transform: translateY(-50%);
        }
        .ipt-err{
          font-size: 0.28rem;
          color: #f00;
          position: absolute;
          bottom: 20px;
          right: 90px;
          z-index: 10;
        }
        .tocalbtn{
          width: 6.9rem;
          height: 0.8rem;
          font-size: 0.36rem;
          color: #fff;
          font-weight: 500;
          line-height: 0.36rem;
          background: #1CAD67 ;
          margin: 0 0 0.47rem 0;
          border: none;
          outline: none;
          cursor: pointer;
          &:hover{
            background: #20c374;
          }
        }
      }
      .ipt-answer{
        flex: none;
        text-align: center;
        .ipt-as-title{
          font-size: 0.38rem;
          color: #000;
          font-weight: 500;
          line-height: 0.53rem;
        }
        .ipt-as-val{
          font-size: 0.9rem;
          color: #FF7F00;
          font-weight: 500;
          line-height: 1.26rem;
        }
        .valunit{
          font-size: 0.46rem;
          font-weight: 500;
          line-height: 0.46rem;
        }
      }
    }
    .contact-part{
      background: #fff;
      padding-top: 0.5rem;
      position: relative;
      overflow: hidden;
      box-sizing: border-box;
      &::before{
        content: '';
        width: 1.65rem;
        height: 0.88rem;
        background: #1CAD67;
        position: absolute;
        top: 0;
        left: -0.9rem;
        z-index: 1;
        transform: skewX(-60deg);
      }
      &::after{
        content: '';
        width: 1.65rem;
        height: 0.88rem;
        background: #1CAD67;
        position: absolute;
        top: 0;
        right: -0.9rem;
        z-index: 1;
        transform: skewX(60deg);
      }
      .tat-top, .tat-bottom{
        align-items: flex-start;
      }
      .tat-top{
        padding: 0 0.3rem;
        margin-bottom: 2.76rem;
        &.tat-unzh-top{
          margin-bottom: 1.8rem;
        }
      }
      .tat-bottom{
        background: #4B4B4B;
        padding: 0.63rem 0.3rem 0.84rem;
        position: relative;
        z-index: 5;
      }
      .tat-inner{
        position: relative;
      }
      .tat-title{
        font-size: 0.5rem;
        color: #3B3D45;
        font-weight: 500;
        text-align: center;
        line-height: 0.7rem;
        margin-bottom: 0.42rem;
        position: relative;
        z-index: 5;
        &.tat-bt-title{
          color: #fff;
          margin-bottom: 0.28rem;
        }
      }
      .tat-cnt{
        font-size: 0.3rem;
        color: #3B3D45;
        line-height: 0.5rem;
        word-break: break-all;
        padding-left: 0.45rem;
        margin-bottom: 0.26rem;
        position: relative;
        z-index: 5;
        .tat-cnt-green{
          font-size: 0.36rem;
          color: #1CAD67 ;
          font-weight: 500;
        }
      }
      .round{
        width: 0.3rem;
        height: 0.3rem;
        background: #1CAD67;
        margin-right: 0.15rem;
        top: 0.1rem;
        border-radius: 50%;
      }
      .tat-zan{
        width: 1.43rem;
        position: absolute;
        top: 0.54rem;
        right: -0.3rem;
        z-index: 1;
      }
      .tat-map{
        width: 100%;
        position: absolute;
        top: 3.34rem;
        left: 50%;
        z-index: 1;
        transform: translateX(-50%);
      }
      .tat-info{
        font-size: 0.3rem;
        color: #fff;
        text-align: center;
        line-height: 0.44rem;
        margin-bottom: 0.43rem;
      }
      .tat-list{
        display: block;
      }
      .tat-item{
        flex: none;
        margin-bottom: 0.42rem;
        &:last-child{
          margin-bottom: 0;
        }
      }
      .tatimgs{
        width: 0.78rem;
        height: 0.78rem;
      }
      .tat-item-cnt{
        font-size: 0.3rem;
        color: #fff;
        font-weight: bold;
        margin-top: 0.19rem;
      }
    }
  }
}
</style>
